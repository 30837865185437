<template>
  <div class="tribeify-card" @click="$emit('select', value)">
    <div class="tribeify-card-amount">
      <div>{{ amount }}</div>
      <span class="amount-end-text">Points</span>
    </div>
    <div class="tribeify-card-title">{{ title }}</div>
  </div>
</template>

<script>
export default {
  name: "Card",
  props: ["amount", "title", "value"],
};
</script>

<style scoped lang="scss">
.tribeify-card {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 10px;
  cursor: pointer;

  .tribeify-card-amount {
    text-align: center;
    box-shadow: 0px 2px 4px #00000029;
    border-radius: 5px 0px 0px 5px;
    background-color: var(--primary-color);
    padding: 4px;
    width: 70px;
    > div {
      margin: 0 4px;
      &:first-child {
        font-weight: bold;
        font-size: 18px;
        margin-bottom: -6px;
      }
    }
    .amount-end-text {
      font-weight: 200;
      font-size: 13px;
    }
  }
  .tribeify-card-title {
    flex: 1 2 100%;
    box-shadow: 0px 2px 4px #00000029;
    border: 1px solid #e5e5e5;
    border-radius: 0px 5px 5px 0px;
    padding: 10px 8px;
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: 440px) {
  .tribeify-card-amount {
    min-width: 64px;
  }
}
</style>
