export const STATUS = {
  0: 'Total',
  1: 'Draft',
  2: 'Active',
  3: 'Archived',
};
export const TYPE = {
  1: 'Survey',
  2: 'Poll',
};
export const FREQUENCY = {
  1: 'Weekly',
  2: 'Monthly',
  3: 'Bi-Weekly',
  4: 'Daily',
  5: 'Performance Based',
  6: 'One time',
};
