<template>
  <div class='tribeify-layout'>
    <Header />
    <Content />
    <Footer />
  </div>
</template>

<script>
import "../globalMixins";

import Header from './layout/header'
import Content from './layout/content'
import Footer from './layout/footer';

export default {
  name: 'Main',
  components: {
    Header, 
    Content,
    Footer
  }
}
</script>

<style lang="scss">
.tribeify-layout {
  display: flex;
  flex-direction: column;
}
body {
  --primary-color: #0c66d0;
  --secondary-color: #1169d1;
}
</style>