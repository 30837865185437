import Vue from "vue";
import router from "./router"

Vue.config.productionTip = false;

setTimeout(() => {
  new Vue({
    data: {
      currentRoute: window.location.pathname
    },
    computed: {
      ViewComponent () {
        return router[this.currentRoute]
      }
    },
    render (h) { return h(this.ViewComponent) }
  }).$mount("#tribeify-customer-experience"); 
}, 2000);

