<template>
  <div class="unique-sharing-url-container">
    <Title title="Unique Sharing URL" />
    
    <div class="content-wrapper">
      <div class="tbl-unique-sharing-url" v-if="0 < share_urls.length">
        <v-client-table :columns="columns" v-model="share_urls" :options="options">
          <div slot="url" slot-scope="props" target="_blank">
            <div class="tribeify-section-item-value-flex-start">
              <a :href="invite_url+'&is_unique_sharing_url=true#refer-a-friend'"
                class="tribeify-stats-url"
                @click="share(props.row)"
                v-if="!isDateExpired(new Date(props.row.expiry_date))"
              >
                <!-- {{ props.row.url.replace("https://", "") }} -->
                <div class="tribeify-cta-copy">
                  <div class="tribeify-icon-copy">
                    <TribeifyIconLinkCopy />
                  </div>
                  <span class="share-text">Share</span>
                </div>
              </a>
            </div>
          </div>
          <div slot="expiry_date" slot-scope="props">
            <div v-if="!isDateExpired(new Date(props.row.expiry_date))">
              {{ getResultDateFormat(props.row.expiry_date, props.row.campaign_code) }}
            </div>
            <div class="text-danger" v-else>EXPIRED</div>
          </div>
        </v-client-table>
      </div>

      <div v-else class="text-center my-3">No Data</div>
    </div>
  </div>
</template>

<script>
  import surveyActions from "@/mixins/surveyActions.mixin";
  import dateActions from "@/mixins/dateActions.mixin";
  import Title from "../common/title";
  import TribeifyIconLinkCopy from "@/components/icons/TribeifyIconLinkCopy";

  export default {
    name: "UniqueSharingUrl",
    mixins: [surveyActions, dateActions],
    components: { Title, TribeifyIconLinkCopy, },
    data() {
      return {
        columns: ['campaign_name', 'url', 'expiry_date'],
        options: {
          headings: {
            campaign_name: 'Campaign',
            url: 'URL',
            expiry_date: 'Expires'
          },
          filterable: false,
          pagination: {
            show: false
          }
        },
        share_urls: [],
        invite_url: ''
      }
    },
    mounted() {
      this.getStats();
      this.invite_url = localStorage.getItem('advocate-invite-url');
    },
    methods: {
      // share(item) {
      //   const customerData = window.tribeifyUser || void 0;
      //   if (!customerData) return;
      //   const queryString = `/${item.campaign_code}?email=${encodeURIComponent(
      //     customerData.email
      //   )}&first_name=${customerData.firstName}&last_name=${
      //     customerData.lastName
      //   }`;
      //   location.href = `https://invite.${
      //     new URL(window.location.href).host
      //   }${queryString}`;
      // }
    }
  };
</script>

<style lang="scss">
  .unique-sharing-url-container {
    .content-wrapper {
      .tbl-unique-sharing-url {
        .VueTables__table {
          thead, tbody {
            tr {
              border: 0;
              border-bottom: 1px solid #DDD;

              th, td {
                border: 0;
                padding-left: 0;

                a {
                  text-decoration: none;
                }
              }
            }
          }
          tbody {
              tr {
                &:hover {
                  background-color: #F8F9F9;
                }

                td {
                  padding-left: 3px;
                  box-shadow: unset;

                  .share-text {
                    margin-left: -4px;
                  }
                }
              }
            }
        }

        .tribeify-cta-copy {
          background: unset;
          display: flex;
          color: var(--primary-color);
          cursor: pointer;
          .tribeify-icon-copy {
            width: 22px;
          }
        }

        .VuePagination__pagination {
          justify-content: end;
          li {
            a {
              font-size: 9px;
              &.active {
                background-color: var(--primary-color);
              }
            }
          }
        }

        .VuePagination__count, .VueTables__limit-field {
          display: none;
        }
      }
    }
  }
</style>
