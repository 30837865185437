import {BaseApiService} from './BaseApiService';

export class TribeifyApiService extends BaseApiService{
  constructor() {
    super();
    this.API_URL = window.config.API_URL;
  }

  buildHeaders() {
    return {
      "Content-Type": "application/json",
    };
  }
}
