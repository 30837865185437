import axios from "axios";

export class BaseApiService {
  constructor() {

  }

  buildUrl(resource) {
    return `${this.API_URL}/${resource}`;
  }

  async get(resource, query = {}) {
    const queryString = Object.keys(query)
      .filter((key) => query[key])
      .map((key) => `${key}=${query[key]}`)
      .join("&");

    const { data } = await axios.get(
      this.buildUrl(resource) + `${queryString ? `?${queryString}` : ""}`,
      {
        headers: this.buildHeaders(),
      }
    );

    return data;
  }

  async post(resource, body) {
    return axios.post(this.buildUrl(resource), body, {
      headers: this.buildHeaders(),
    });
  }
}
