<template>
  <div class='tribeify-tabs-container'>
      <div class="tribeify-tabs-wrapper">
        <div class="tribeify-tabs container">
          <div v-for="tab in tabs" :key="tab.name" :class="{ 'is-active': tab.isActive }">
              <a :href="tab.href" @click="selectTab(tab.href)">{{ tab.name }}</a>
          </div>
        </div>
      </div>

      <div class="tribeify-tabs-item">
          <slot></slot>
      </div>
  </div>
</template>

<script>
  export default {
    name: 'Tabs',
    data() {
      return {tabs: [] };
    },
    created() {
      this.tabs = this.$children;
    },
    methods: {
      selectTab(selectedTabHref) {
        const refer_a_friend = '#refer-a-friend';
        selectedTabHref = selectedTabHref || refer_a_friend;

        if (refer_a_friend != selectedTabHref) {
          document.title = window.location.host;
          window.history.replaceState({}, document.title, '/' + selectedTabHref);
        }

        this.tabs = this.tabs.map(tab => {
          tab.isActive = tab.href === selectedTabHref;
          return tab;
        });
        
        const scrollToSection = () => {
          const el = document.querySelector(`[href="${selectedTabHref}"]`);
          if (undefined === el?.click) {
            return setTimeout(() => scrollToSection(), 100);
          }
          el.click();
        }
        scrollToSection();
      }
    }
  }
</script>

<style scoped lang="scss">
  .tribeify-tabs-container {
    width: 100%;

    .tribeify-tabs-wrapper {
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border-bottom: 1px solid #999;
      position: fixed;
      width: 100%;
      top: 211px;
      z-index: 99;
      .tribeify-tabs {
        width: 46%;
        font-size: 16px;
        display: flex;
        div {
          width: 100%;
          
          &.is-active {
            a {
              background-color: unset;
              color: var(--primary-color);
            }
          }

          a {
            text-decoration: none;
            width: 100%;
            display: inline-block;
            color: #68696d;
            font-size: 14px;
            font-weight: 500;
            text-align: center;
            padding: 12px 0px;
            &:hover {
              color: var(--primary-color);
            }
          }
        }
      }
    }
  }
</style>
