<template>
  <div>
    <div class="tribeify-content" id="tribeify_content_id">
      <ContentAccordionView
        v-if="isMobileView"
        ref="refContentAccordionView"
        class="tribeify-mobile-view"
      />
      <ContentTabView
        v-else
        ref="refContentTabView"
        class="tribeify-desktop-view"
      />
    </div>
    <Toast
      v-if="isToast"
      title="Incorrent Survey/Poll"
      message="There is no survey or poll with provided data."
      variant="danger"
      @closeToast="isToast = false"
    />
    
  </div>
</template>

<script>
import { TYPE } from "@/helpers/constants/survey";
import surveyActions from "@/mixins/surveyActions.mixin";
import WebFont from "webfontloader";

import ContentAccordionView from "./contentAccordionView";
import ContentTabView from "./contentTabView";
import Toast from "@/components/common/Toast";

export default {
  name: "Content",
  components: {
    ContentAccordionView,
    ContentTabView,
    Toast,
  },
  mixins: [surveyActions],
  data() {
    return {
      isMobileView: Number,
      bodyCopyFont: String,
      headingFont: String,
      primaryColor: String,
      secondaryColor: String,
      isToast: false,
    };
  },
  methods: {
    getBrandStyles() {
      return {
        ".tribeify-layout": {
          // "font-family": this.bodyCopyFont,
          "font-size": "14px",
        },
        ".tribeify-title": {
          color: "#000",
          "font-family": this.headingFont,
          "font-weight": 700,
          "font-size": "22px;"
        },
        ".tribeify-heading": {
          color: "#000",
        },
        ".tribeify-accordion-item-trigger": {
          border: "none",
          "border-top": `5px solid ${this.primaryColor}`,
          color: this.primaryColor,
          "font-family": this.headingFont,
          "font-weight": 700,
        },
        ".tribeify-section-item-title": {
          color: "#000",
        },
        ".tribeify-section-item-value": {
          color: "#000",
        },
        ".tribeify-stats-url": {
          color: this.primaryColor,
        },
        ".tribeify-card-title": {
          "font-family": this.headingFont,
          "font-weight": 700,
          color: "#000",
        },
        ".tribeify-cta-copy": {
          border: "none",
          color: this.primaryColor,
        },
        ".tribeify-card-amount": {
          background: this.primaryColor,
          color: "#fff",
          "font-family": this.headingFont,
          "font-weight": 700,
        },
        ".tribeify-history-complition-text": {
          "font-weight": 700,
          color: "#000",
        },
        ".tribeify-cta-link": {
          color: this.primaryColor,
        },
        ".tribeify-survey-history-value": {
          color: "#000",
        },
        ".tribeify-complited-survey-title": {
          color: "#000",
        },
        ".tribeify-erase-data-cta": {
          background: this.primaryColor,
          color: "#fff",
        },
        ".tribeify-button": {
          "font-family": this.headingFont,
          background: this.primaryColor,
          color: "#fff",
        },
        ".tribeify-tabs .is-active a": {
          background: this.primaryColor,
          color: "#fff",
        },
        ".tribeify-tabs a": {
          color: this.primaryColor,
        },
        ".tribeify-points": {
          color: this.primaryColor,
          "font-family": this.headingFont,
          "font-weight": 700,
        },
        ".tribeify-pending-points": {
          "font-family": this.headingFont,
          "font-weight": 700,
          color: "#000",
        },
        ".tribeify-available-store-credit": {
          color: this.primaryColor,
        },
        ".tribeify-redeem-store-credit-text": {
          "font-family": this.bodyCopyFont,
          "font-weight": 700,
          color: "#000",
        },
        ".tribeify-points-description": {
          color: "#000",
        },
        ".tribeify-points-conversion": {
          color: "#000",
        },
        ".tribeify-info-card-title": {
          "font-family": this.bodyCopyFont,
          "font-weight": 700,
          color: "#000",
        },
        ".tribeify-layout .sv-checkbox--checked, .sv-radio--checked": {
          "background-color": this.$brandAttributes.primaryColor,
          color: this.$textColorBasedOnBgColor,
          "border-color": `${this.$brandAttributes.primaryColor} !important`,
        },
        ".tribeify-layout .sv-checkbox--checked .sv-checkbox__svg, .sv-previous-button-label":
          {
            fill: this.$textColorBasedOnBgColor,
          },
        ".tribeify-layout .sv-container-modern": {
          color: this.$brandAttributes.primaryColor,
        },
        ".tribeify-layout .sv-btn, .sv-comment.sv-question__other": {
          color: this.$textColorBasedOnBgColor,
        },
        ".tribeify-layout .sv-footer input": {
          "font-size": "1.3em",
        },
        ".tribeify-layout .sv-dropdown:focus": {
          "background-image": `url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 10 10' style='enable-background:new 0 0 10 10;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23${this.$primaryColorAfterHash};%7D%0A%3C/style%3E%3Cpolygon class='st0' points='2,2 0,4 5,9 10,4 8,2 5,5 '/%3E%3C/svg%3E ");`,
        },
        ".tribeify-layout .sv-container-modern h1, .tribeify-layout .sv-container-modern h2, .tribeify-layout .sv-container-modern h3, .tribeify-layout .sv-container-modern h4, .tribeify-layout .sv-container-modern h5, .tribeify-layout .sv-container-modern h6":
          {
            color: this.$brandAttributes.primaryColor,
          },
      };
    },
    getBrandStylesContent() {
      const brandStyles = this.getBrandStyles();
      return Object.keys(brandStyles).reduce((cssContent, selector) => {
        let styleProperty = `${selector} { `;
        styleProperty += Object.keys(brandStyles[selector]).reduce(
          (properties, property) =>
            properties + `${property}: ${brandStyles[selector][property]};`,
          ""
        );
        styleProperty += `} `;
        return cssContent + styleProperty;
      }, "");
    },
    injectStyles() {
      document
        .querySelector(".tribeify-content")
        .insertAdjacentHTML(
          "afterbegin",
          `<style>${this.getBrandStylesContent()}</style>`
        );
    },
    setResolution(width) {
      this.isMobileView = width <= 640;
    },
    listenWindowResize() {
      this.setResolution(window.innerWidth);
    },
    async promoteSurveyPollByUrlRedirect() {
      const params = this.getPromoteSurveyPollUrlSearchParams();
      const searchParamId = params.survey || params.poll;

      if (!searchParamId) return;

      const surveyPollType = Object.entries(TYPE).find((type) =>
        Object.keys(params).find((param) => param === type[1].toLowerCase())
      )[0];

      try {
        await this.getPromoteSurveyPollData(searchParamId, surveyPollType);
        this.openPromoteSurveyPollModal();
      } catch ({ response: { status } }) {
        if (status === 401) {
          await this.getPromoteSurveyPollResult(searchParamId, surveyPollType);
          this.openPromoteSurveyPollModal();
        }
        if (status === 404) {
          this.isToast = true;
        }
      }
    },
    openPromoteSurveyPollModal() {
      const payload =
        this.promoteSurveyPollData || this.promoteSurveyPollResult;

      if (!payload) return;

      const method = this.promoteSurveyPollData
        ? "onSurveyPollSelect"
        : "onSurveyResultSelect";

      if (this.isMobileView) {
        this.showPromoteSurveyPoll(
          this.$refs.refContentAccordionView.$refs.refSurveysPollsAccordionItem,
          method,
          payload,
          true
        );
        return;
      }
      this.$refs.refContentTabView.$refs.firstTabItem.isActive = false;
      this.showPromoteSurveyPoll(
        this.$refs.refContentTabView.$refs.refSurveysPollsTabItem,
        method,
        payload,
        true
      );
    },
  },
  created() {
    this.setResolution(window.innerWidth);
  },
  async mounted() {
    window.addEventListener("resize", this.listenWindowResize);
    await this.$getBrandAttributes();
    this.bodyCopyFont = this.$brandAttributes.bodyCopyFont;
    this.headingFont = this.$brandAttributes.headingFont;
    this.primaryColor = this.$brandAttributes.primaryColor;
    this.secondaryColor = this.$brandAttributes.secondaryColor;
    WebFont.load({
      google: {
        families: [
          this.bodyCopyFont,
          this.headingFont,
        ],
      },
    });
    this.injectStyles();
    await this.promoteSurveyPollByUrlRedirect();
  },
  destroyed() {
    window.removeEventListener("resize", this.listenWindowResize);
  },
};
</script>
