<template>
  <div class="tribeify-content-tabs">
    <Tabs ref="tabsRef">
      <TabItem href="#stats" name="Stats" :selected="activeTabHref === 'stats'"></TabItem>
      <TabItem href="#surveys" :selected="activeTabHref === 'surveys'" name="Surveys/Polls"></TabItem>
      <TabItem href="#refer-a-friend" :selected="activeTabHref === 'refer-a-friend'"  name="Refer a friend"></TabItem>
      <!-- <TabItem :href="invite_url+'#refer-a-friend'" :selected="activeTabHref === 'refer-a-friend'"  name="Refer a friend"></TabItem> -->
      <TabItem href="#points" :selected="activeTabHref === 'points'" name="Points/Store Credits"></TabItem>
      <!-- <TabItem href="#right-to-be-forgotten" :selected="activeTabHref === 'right-to-be-forgotten'"  name="Right to be Forgotten"></TabItem> -->
    </Tabs>

    <div class="advocate-content container">
      <div id="stats"></div>
      <div class="stats card"><Stats /></div>

      <!-- Surveys/Polls section -->
      <div id="surveys"></div>
      <div class="surveys card"><Surveys type="survey" /></div>
      <div class="survey-img text-center">
        <img :src="require('../../assets/images/people-06.png')" class="img-people-06" />
      </div>
      <div class="polls card"><Surveys type="poll" /></div>
      <!-- End surveys/polls section -->

      <!-- Refer a friend section -->
      <div id="refer-a-friend"></div>
      <div class="refer-a-freind card">
        <ReferAFriend />
      </div>
      <div class="refer-a-friend-img">
        <img :src="require('../../assets/images/people-05.png')" class="img-people-05" />
        <img :src="require('../../assets/images/people-04.png')" class="img-people-04 ms-4" />
      </div>

      <div id="referral-history"></div>
      <div class="referral-history card">
        <ReferralHistory />
      </div>

      <div class="unique-sharing-url card">
        <UniqueSharingUrl />
      </div>
      <!-- End refer a friend section -->

      <div id="points"></div>
      <div class="points-store-credits card"><PointsStoreCredits /></div>

      <div id="right-to-be-forgotten"></div>
      <div class="right-to-be-forgotten card"><RightToBeForgotten /></div>
    </div>
  </div>
</template>

<script>
  import RightToBeForgotten from "../sections/rightToBeForgotten";
  import Surveys from "../sections/surveys";
  import Stats from "../sections/stats";
  import ReferAFriend from "../sections/referAFriend";
  import ReferralHistory from "../sections/referralHistory";
  import UniqueSharingUrl from "../sections/uniqueSharingUrl";
  import PointsStoreCredits from "../sections/pointsStoreCredits";

  // import Notifications from '../sections/notifications'
  // import Offers from '../sections/offers'
  // import Friends from '../sections/friends'

  import Tabs from "../common/tabs";
  import TabItem from "../common/tabItem";

  export default {
    name: "ContentTabView",
    components: {
      Tabs,
      TabItem,
      PointsStoreCredits,
      // Notifications,
      // Offers,
      RightToBeForgotten,
      Surveys,
      // Friends,
      Stats,
      ReferAFriend,
      ReferralHistory,
      UniqueSharingUrl,
    },
    data() {
      return {
        activeTabHref: '',
        defaultTabHref: 'stats',
        invite_url: ''
      };
    },
    methods: {
      setNewActiveTabHref() {
        this.activeTabHref = location.hash.replace('#', '')  || this.defaultTabHref;
        this.$refs.tabsRef.selectTab(location.hash);
        this.invite_url = localStorage.getItem('advocate-invite-url');
      }
    },
    mounted () {
      this.setNewActiveTabHref()
      window.addEventListener('popstate', this.setNewActiveTabHref);
    },
    destroyed () {
      window.removeEventListener('popstate', this.setNewActiveTabHref)
    },
  };
</script>

<style scoped lang="scss">
  .advocate-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 800px;

    .card {
      border: 1px solid #eee;
      padding: 20px;
      border-radius: 5px;
      margin-top: 20px;
      background-color: #FFFFFF;
    }

    .survey-img {
      img {
        width: 132px;
        margin-top: 18px;
      }
    }

    .refer-a-friend-img {
      text-align: center;
      .img-people-05 {
        width: 80px;
      }
      .img-people-04 {
        width: 70px;
        margin-top: 80px;
      }
    }
  }
</style>
