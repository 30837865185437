import { BaseApiService } from './BaseApiService';

export class ApiService extends BaseApiService{
  constructor() {
    super();
    this.API_URL = window.config.TENANT_API_URL + "/api";
  }

  buildHeaders() {
    return {
      "Content-Type": "application/json",
    };
  }
}
