<template>
  <transition name="tribeify-toasts" appear>
    <div class="tribeify-toast-target">
      <div class="tribeify-toast-outer" :class="variant">
        <div class="tribeify-toast">
          <div class="tribeify-toast-header">
            <div class="tribeify-toast-title">
              {{ title }}
            </div>
            <button
              type="button"
              aria-label="Close"
              class="tribeify-toast-close"
              @click="closeToast"
            >
              ×
            </button>
          </div>
          <div class="tribeify-toast-body">{{ message }}</div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "TribeifyToast",
  props: ["title", "message", "variant"],
  methods: {
    closeToast() {
      this.$emit("closeToast");
    },
  },
};
</script>

<style lang="scss" scoped>
.tribeify-toast-target {
  position: absolute;
  max-width: 350px;
  width: 100%;
  top: 2rem;
  left: 0;
  right: 0;
  padding: 0;
  margin: auto;
  .tribeify-toast-outer {
    display: block;
    position: relative;
    max-width: 350px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background-clip: padding-box;
    z-index: 1;
    border-radius: 0.25rem;
    .tribeify-toast {
      flex-basis: 350px;
      max-width: 350px;
      font-size: 1rem;
      background-color: hsla(0, 0%, 100%, 0.95);
      background-clip: padding-box;
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: 0 0.25rem 0.75rem rgb(0 0 0 / 10%);
      border-radius: 0.25rem;
      .tribeify-toast-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.25rem 0.75rem;
        color: #6c757d;
        font-weight: 700;
        background-color: hsla(0, 0%, 100%, 0.85);
        background-clip: padding-box;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        border-top-left-radius: calc(0.25rem - 1px);
        border-top-right-radius: calc(0.25rem - 1px);
        .tribeify-toast-close {
          background-color: transparent;
          border: 0;
          font-size: 1.5rem;
          cursor: pointer;
        }
      }
      .tribeify-toast-body {
        padding: 0.75rem;
      }
    }
    &.danger {
      color: #721c24;
      .tribeify-toast-header {
        color: #721c24;
        background-color: rgba(248, 215, 218, 0.85);
        border-bottom-color: rgba(245, 198, 203, 0.85);
      }
      .tribeify-toast-body {
        background-color: #fcedee;
      }
    }
  }
}

.tribeify-toast-target {
  transition: all 0.5s;
}
.tribeify-toasts-enter,
.tribeify-toasts-leave-to {
  opacity: 0;
  transform: scale(0.9);
}
.tribeify-toasts-leave-active {
  transition: all 0.5s;
}
</style>
