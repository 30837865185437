<template>
  <div>
    <!-- Survey/Poll List -->
    <Title title="Surveys" v-if="'survey'===type" />
    <Title title="Polls" v-if="'poll'===type" />
    <IconCard
      v-if="'survey'===type"
      title="Complete surveys and polls to earn rewards points to towards you purchases"
    />
    <div v-if="surveyPollList[0]" class="tribeify-wrapper">
      <Card
        v-for="item in surveyPollList"
        :key="item.id"
        :amount="item.points"
        :title="item.content.title"
        @select="onSurveyPollSelect"
        :value="item"
      />
    </div>
    <div v-else class="tribeify-wrapper">
      There is no available {{ 'survey' === type ? 'surveys':'polls' }} for you yet.
    </div>
    <!-- End Survey/Poll List -->

    <!-- Survey/Poll History -->
    <Title class="tribeify-history-title mt-4" title="History" />
    <div v-if="surveyPollHistoryList[0]" class="tribeify-wrapper">
      <div
        v-for="result in surveyPollHistoryList"
        :key="result.id"
        class="tribeify-survey-history"
      >
        <div class="tribeify-survey-history-item">
          <div class="tribeify-survey-history-content">
            <div class="tribeify-survey-history-title">
              <span class="tribeify-history-complition-text">
                {{ 'survey' === type ? 'Survey':'Poll' }} taken - {{result.survey.points}} pt.
              </span>
              <a class="tribeify-cta-link" @click="onSurveyResultSelect(result)">
                <b>See results</b>
              </a>
            </div>
            <div class="tribeify-complited-survey-title">{{
              result.survey.title
            }}</div>
          </div>
          <div class="tribeify-survey-history-value">
            {{ getShortDateFormat(result.updated_at) }}
          </div>
        </div>
      </div>
    </div>
    <div v-else class="tribeify-wrapper">No answers for {{ 'survey' === type ? 'surveys':'polls' }} yet.</div>
    <!-- End Survey/Poll History -->

    <Modal
      ref="tribiefySurveyPollModal"
      :title="modalTitle"
      :hideHeader="isSurveyPollCompleteMessage"
      :hideFooter="!isSurveyPollResult"
      @closeModal="reset"
    >
      <template #modal-body>
        <Survey
          v-if="isSurveyPoll"
          :surveyPollData="surveyPollData"
          @showSurveyCompleteMessage="onShowSurveyCompleteMessage"
          class="tribeify-survey-welcome"
        />
        <SurveyResult v-else-if="isSurveyPollResult" :surveyResult="surveyResult">
          <div
            v-if="isPromoteResult"
            class="tribeify-promote-survey-poll-result-message"
          >
            It looks like you’ve already taken this
            {{ surveyPollType }}.<br />Here are your responses”.
          </div>
        </SurveyResult>
      </template>
    </Modal>
  </div>
</template>

<script>
import { TYPE } from "@/helpers/constants/survey";
import surveyActions from "@/mixins/surveyActions.mixin";
import dateActions from "@/mixins/dateActions.mixin";

import Card from "../common/card";
import Title from "../common/title";
import IconCard from "../common/infoCard";
import Modal from "@/components/common/modal";
import Survey from "@/components/common/Survey";
import SurveyResult from "@/components/common/SurveyResult";

export default {
  name: "Surveys",
  mixins: [surveyActions, dateActions],
  components: {
    Card,
    IconCard,
    Title,
    Modal,
    Survey,
    SurveyResult,
  },
  props: {
    type: { required: true },
  },
  data() {
    return {
      isSurveyPoll: false,
      isSurveyPollResult: false,
      isPromoteResult: false,
      modalTitle: "",
      surveyPollData: {},
      surveyResult: {},
      isSurveyPollCompleteMessage: false,
      surveyPollList: [],
      surveyPollHistoryList: [],
    };
  },
  computed: {
    surveyPollType() {
      return TYPE[
        `${this.surveyPollData.type || this.surveyResult.survey.type}`
      ].toLowerCase();
    },
    surveyCompletePointsStyle() {
      return this.surveyPollData.points.toString().length > 4
        ? { fontSize: "1em", top: "29%" }
        : {};
    },
  },
  methods: {
    async init() {
      await this.getSurveyPollList();
      await this.getSurveyResultList();
      await this.getPollResultList();
      this.initSurveyPollList();
    },
    reset() {
      this.isSurveyPoll = false;
      this.isSurveyPollResult = false;
      this.modalTitle = "";
      this.surveyPollData = {};
      this.surveyResult = {};
      this.isSurveyPollCompleteMessage = false;

      clearInterval(this.__setSurveyBgIntervalId);
      clearInterval(this.__setSurveyModalIntervalId);
    },
    onSurveyPollSelect(payload) {
      this.isSurveyPoll = true;
      this.surveyPollData = payload;
      this.$refs.tribiefySurveyPollModal.openModal();
      this.initSurveyBackground(payload);

      this.__setSurveyModalIntervalId = setInterval(() => {
        let surveyTribeifyModalSelector = document.querySelector(".tribeify-modal")
        if (null == surveyTribeifyModalSelector) {
          return;
        }
        clearInterval(this.__setSurveyModalIntervalId);
      })
    },
    onSurveyResultSelect(payload, isPromoteResult) {
      this.isPromoteResult = isPromoteResult;
      this.isSurveyPollResult = true;
      this.surveyResult = payload;
      this.modalTitle = payload.survey.title;
      this.$refs.tribiefySurveyPollModal.openModal();
    },
    onShowSurveyCompleteMessage() {
      this.isSurveyPoll = false;
      this.isSurveyPollCompleteMessage = true;
    },
    async onCloseModal() {
      this.$refs.tribiefySurveyPollModal.closeModal();
      await this.init();
    },
    initSurveyBackground(payload) {
      if (payload?.content?.backgroundImage) {
        this.__setSurveyBgIntervalId = setInterval(() => {
          let tribeifyModalSelector = document.querySelector(".tribeify-modal");
          if (null == tribeifyModalSelector) {
            return;
          }
          tribeifyModalSelector.classList.add("survey-tribeify-modal");
          tribeifyModalSelector.style.setProperty('--surveyBackgroundImage', `url("${payload.content?.backgroundImage}")`);
          clearInterval(this.__setSurveyBgIntervalId);
        });
      }
    },
    initSurveyPollList() {
      if ('survey' === this.type) {
        this.surveyPollList = this.surveyList;
        this.surveyPollHistoryList = this.surveyResultList;
      } else {
        this.surveyPollList = this.pollList;
        this.surveyPollHistoryList = this.pollResultList;
      }
    }
  },
  async mounted() {
    await this.init();
  },
};
</script>

<style scoped lang="scss">
.tribeify-survey-welcome {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.tribeify-scroll-container {
  max-height: 200px;
  border-bottom: 2px solid #e5e5e5;
}
.tribeify-wrapper {
  margin-bottom: 1.5em;
}
.tribeify-card-title {
  font-size: 16px;
}
.tribeify-survey-history {
  display: flex;
  flex-direction: column;
  justify-content: left;
  flex-wrap: wrap;

  .tribeify-survey-history-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #e5e5e5;
    padding: 2px 0 12px 0;
    .tribeify-survey-history-content {
      margin-right: 4px;
      .tribeify-survey-history-title {
        margin: 0 16px 4px 0;
      }
      .tribeify-history-complition-text {
        font-weight: bold;
      }
      .tribeify-complited-survey-title {
        margin: 0 0 4px 0;
        font-size: 13px;
      }
    }
    .tribeify-survey-history-value {
      align-self: flex-start;
      text-align: right;
      white-space: nowrap;
    }
    &:last-child {
      border-bottom: none;
    }
  }
}
.tiribeify-survey-complete-notification {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  .tiribeify-survey-complete-icon {
    position: relative;
    width: 10em;
    .tribeify-survey-complete-points {
      position: absolute;
      top: 27%;
      left: 0;
      right: 0;
      overflow: hidden;
      text-align: center;
      font-size: 1.3em;
      color: var(--primary-color);
    }
    > svg {
      width: 100%;
      height: 100%;
    }
  }
  .tribeify-survey-complete-message {
    margin-bottom: 2rem;
    font-size: 21px;
    text-align: center;
    color: var(--primary-color);
  }

  .button-wrapper {
    margin-bottom: 2rem;
    .tribeify-button {
      background-color: var(--primary-color) !important;
    }
  }
}
.tribeify-promote-survey-poll-result-message {
  color: #31708f;
  background-color: #d9edf7;
  border: 1px solid #bce8f1;
  border-radius: 2px;
  box-shadow: inset 0 1px 0 rgb(255 255 255 / 70%);
  text-align: center;
  margin-bottom: 1em;
  font-size: 1.5em;
}
.tribeify-info-card {
  margin-bottom: 24px;
}
.tribeify-history-title {
  padding-bottom: 12px;
  border-bottom: 1px solid #ccc;
}

.tribeify-cta-link {
  color: var(--primary-color);
}

@media only screen and (max-width: 640px) {
  .tribeify-survey-history {
    .tribeify-survey-history-title {
      width: 100%;
    }
  }
}
</style>

<style lang="scss">
.tribeify-modal-body {
  margin-bottom: 5rem;
}
.survey-tribeify-modal {
  --surveyBackgroundImage: none;
  background-image: var(--surveyBackgroundImage);
  background-size: contain;

  .sv-body, .sv-page, .sv-completedpage {
    background-color: transparent !important;
  }
  .sv-completedpage {
    &::before {
      display: none !important;
    }
  }
  .sv-body__footer {
    background: transparent;
  }
}
</style>
