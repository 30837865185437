<template>
  <div v-if="selected"><slot></slot></div>
</template>

<script>
export default {
  name: "TabItem",
  props: {
    name: { required: true },
    href: { required: true },
    selected: { default: false },
  },
};
</script>

<style scoped lang="scss">
</style>
