import Vue from 'vue';
import { TribeifyApiService } from '@/helpers/TribeifyApiService';

const globalData = {
  $brandAttributes: '',
};

export default globalData;

Vue.mixin({
  data() {
    return {
      tribeifyApiService: new TribeifyApiService(),
    };
  },
  computed: {
    $brandAttributes() {
      return globalData.$brandAttributes;
    },
    $primaryColorAfterHash() {
      if ('' === globalData.$brandAttributes) return;
      return globalData.$brandAttributes.primaryColor.split('#')[1];
    },
    $textColorBasedOnBgColor() {
      return this.pickTextColorBasedOnBgColor(this.$primaryColorAfterHash);
    },
  },
  methods: {
    async $getBrandAttributes() {
      globalData.$brandAttributes = await this.tribeifyApiService.get(
        'brandattributes'
      );
    },
    pickTextColorBasedOnBgColor(
      bgColor,
      lightColor = '#fff',
      darkColor = '#000'
    ) {
      if (undefined === bgColor) return;
      const red = parseInt(bgColor.substring(0, 2), 16);
      const green = parseInt(bgColor.substring(2, 4), 16);
      const blue = parseInt(bgColor.substring(4, 6), 16);
      const uiColors = [red / 255, green / 255, blue / 255];
      const colors = uiColors.map((col) =>
        col <= 0.03928 ? col / 12.92 : Math.pow((col + 0.055) / 1.055, 2.4)
      );
      const contrast =
        0.2126 * colors[0] + 0.7152 * colors[1] + 0.0722 * colors[2];
      return contrast > 0.22 ? darkColor : lightColor;
    },
  },
});
