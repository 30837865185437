<template>
  <transition name="fade" appear>
    <div class="tribeify-alert" :class="`tribeify-${this.variant}`">
      <slot />
    </div>
  </transition>
</template>

<script>
export default {
  props: ["variant"],
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.tribeify-alert {
  padding: 12px 0;
  &.tribeify-success {
    border: 1px solid #707070;
    color: #fff;
    background: #2ecc71;
    text-align: center;
  }
  &.tribeify-danger {
    color: #ff0000;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>