<template>
  <div v-if="isModalOpen" class="tribeify-personal-data-delete-overlay">
    <div class="tribeify-personal-data-delete-modal">
      <div class="tribeify-personal-data-delete-title">Delete my Personal Data</div>
      <div class="tribeify-personal-data-delete-description">
        <p>You're about to delete your consent data relating to our referral program. </p>
        <p>Click "Verify" below. You will receive an email to confirm this action.</p>
      </div>
      <div class="tribeify-personal-data-delete-buttons">
        <button class="tribeify-personal-data-delete-verify" @click="verify">Verify</button>
        <button class="tribeify-personal-data-delete-cancel" @click="closeModal">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
import { ApiService } from '@/helpers/ApiService';

export default {
  data() {
    return {
      apiService: new ApiService(),
      isModalOpen: false,
    };
  },
  methods: {
    async verify() {
      await this.apiService.post(`right-to-be-forgotten-request/`, { email: this.getAdvocateEmail()});
      this.closeModal(true);
    },
    getAdvocateEmail() {
      return window.tribeifyUser ? window.tribeifyUser.email.toLocaleLowerCase().replace(" ", '+') : undefined;
    },
    openModal() {
      this.isModalOpen = true;
    },
    closeModal(modalStatus = false) {
      this.isModalOpen = false;
      this.$emit("closeModal", modalStatus);
    },
  },
};
</script>

<style lang="scss" scoped>
.tribeify-personal-data-delete-overlay {
    background: rgba(0, 0, 0, 0.2);
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    overflow-x: hidden;
    overflow-y: auto;
}
.tribeify-personal-data-delete-modal {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  overflow: hidden;
  position: absolute;
  background: #fff;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999999999999;

  .tribeify-personal-data-delete-title {
    background: #FF0000;
    color: #fff;
    letter-spacing: 0.9px;
    font-size: 25px;
    padding: 48px 33px;
  }

  .tribeify-personal-data-delete-description {
    font-size: 14px;
    color: #040404;
    padding: 20px 30px 60px 30px;
    p {
      margin-bottom: 20px;
    }

  }

  .tribeify-personal-data-delete-buttons {
    padding: 0px 20px 22px 20px;
    display: flex;
    flex-direction: row;
    .tribeify-personal-data-delete-verify {
      background: #FF0000;
      color: #fff;
      padding: 11px 60px;
      font-size: 14px;
      border: none;
      outline: none;
      box-shadow: 0px 5px 10px #CBD1DF80;
      border-radius: 5px;
      cursor: pointer;
      transition: background .3s;
      margin-right: 10px;

      &:hover {
        background: #d30000;
      }
    }

    .tribeify-personal-data-delete-cancel {
      color: #040404;
      padding: 11px 40px;
      font-size: 14px;
      background: transparent;
      border: none;
      outline: none;
      cursor: pointer;

      transition: background .3s;

      &:hover {
        background: #efefef;
      }
    }
  }
}
</style>