<template>
  <div class="tribeify-info-card">
    <div class="tribeify-info-card-icon">
      <TribeifyIconAward />
    </div>
    <div class="tribeify-info-card-title">{{ title }}</div>
  </div>
</template>

<script>
import TribeifyIconAward from "@/components/icons/TribeifyIconAward";

export default {
  name: "InfoCard",
  props: ["title"],
  components: { TribeifyIconAward },
};
</script>

<style scoped lang="scss">
.tribeify-info-card {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 12px;
  padding: 10px;
  box-shadow: 0px 2px 4px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 0px 5px 5px 0px;

  .tribeify-info-card-icon {
    margin-right: 12px;

    svg {
      display: block;
    }
  }

  .tribeify-info-card-title {
    text-align: left;
    font-size: 12px;
  }
}
</style>
