<template>
    <div class="landing-page-header">
      <div class="apparel-idol-logo">
        <img :src="require('../../assets/images/apparel-idol-logo.png')"/>
      </div>
      <div class="header-sub-title">Membership Rewards</div>
  </div>
</template>

<script>
export default {
  name: "LandingPage",
};
</script>

<style scoped lang="scss">
  .landing-page-header {
    background-color: var(--primary-color);
    height: 200px;
    .apparel-idol-logo {
      padding-top: 38px;
      text-align: center;
      padding-bottom: 5px;

      img {
        width: 200px;
      }
    }
    .header-sub-title {
      font-size: 22px;
      opacity: 0.9;
      color: #fff;
      text-align: center;
    }
  }
  @media (max-width: 576px) {
    .landing-page-header {
      height: 120px;
      .apparel-idol-logo {
        padding-top: 10px;
      }
    }
  }
</style>