<template>
  <div id="tribeify-customer-experience">
    <div v-if="null === customer_id || isHasCouponCode || isHasIsFriend"><LandingPage /></div>
    <div v-else><Main /></div>
  </div>
</template>

<script>
import Main from "./components/main";
import LandingPage from "./components/layout/landingPage";
import "./assets/scss/main.scss";

import Vue from "vue";
import axios from "axios";
import { ClientTable } from 'vue-tables-2';
import advocatePortal from "@/mixins/advocatePortal.mixin";
import surveyActions from "@/mixins/surveyActions.mixin";

Vue.use(ClientTable);

export default {
  name: "App",
  mixins: [advocatePortal, surveyActions],
  components: {
    Main,
    LandingPage,
  },
  data() {
    return {
      customer_id: null,
      userEmail: {},
      isHasCouponCode: false,
      isHasIsFriend: false,
    }
  },
  mounted() {
    this.userEmail = this.getAdvocateEmail;
    this.customer_id = localStorage.getItem('shopify-customer-id');
    this.initNoLoginRedirectURL();

    window.addEventListener('message', (event) => {
      const messageType = event.data?.type;
      if (messageType != 'refer-a-friend-form-mounted') {
        return;
      }
      this.getUserData();
    })
  },
  methods: {
    getUserData() {
      const url = `${window.config.TENANT_API_URL}/api/advocate/${this.userEmail}`;
      axios.get(url).then((res) => {
          this.getAdocateReferAfriendSource(res.data);
      });
    },
    initNoLoginRedirectURL() {
      setTimeout(() => {
        const queryStringJsonObject = this.queryStringToJsonObj(window.location.href);
        this.isHasCouponCode = Object.prototype.hasOwnProperty.call(queryStringJsonObject, 'coupon_code');
        this.isHasIsFriend = Object.prototype.hasOwnProperty.call(queryStringJsonObject, 'isfriend');
        if (null != this.customer_id) {
          return;
        }
        if (this.isHasCouponCode || this.isHasIsFriend) {
          return;
        }
        window.location.href = `${window.config.SHOP_URL}/account/login?isGoToAdvocate=true`;
      }, 600);
    },
    postMessageToReferAFriendIframe(postValue) {
      const iframeAdvocateTribeifyFrontend = document.querySelector('#advocateTribeifyFrontendIframe');
      const messageType = 'form-refer-friend-data';
      if (null == iframeAdvocateTribeifyFrontend) {
        return;
      }
      if (postValue) {
        if (undefined != postValue.personal_message) {
          postValue.personal_message = decodeURIComponent(postValue.personal_message.replace(/\+/g, " "));
        }
        iframeAdvocateTribeifyFrontend.contentWindow.postMessage({type: messageType, value: postValue}, '*');
      }
    },
    getAdocateReferAfriendSource(customerData) {
      let advocate_url = window.location.origin;
      let url =  advocate_url.replace('adv', 'invite');
      const queryString = customerData ? `?email=${encodeURIComponent(customerData.email)}&first_name=${customerData.firstName}&last_name=${customerData.lastName}&customerId=${this.customer_id}` : `customerId=${this.customer_id}`;
      let query_url =  `${url}${queryString}`;

      axios.get(query_url).then((res) => {
        let url_params = res.data.match(/(\?refcode=.*[0-9])/g)[0].replaceAll('&amp;', '&');
        url_params = url + '/' + url_params;
        const queryStringJsonObject = this.queryStringToJsonObj(url_params);
        this.postMessageToReferAFriendIframe(queryStringJsonObject);
      });
    },
  }
};
</script>

<style>
  * {
    margin: 0;
    padding: 0;
    font-family: inherit;
  }
  .tribeify-content {
    height: calc(100vh - 256px);
    overflow: auto;
    position: relative;
    top: 256px;
    padding-bottom: 50px;
    scroll-behavior: smooth;
    border-top: 7px solid #ddd;
  }
</style>
