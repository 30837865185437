export default {
  methods: {
    getResultDateFormat(date, code = null) {
      const data = new Date(date);
      const options = { month: "2-digit", day: "2-digit", year: "numeric" };
      return code === "WEB"
        ? "NEVER"
        : data.toLocaleDateString("en-US", options);
    },
    isDateExpired(date) {
      const today = new Date();
      if (date.setHours(0, 0, 0, 0) <= today.setHours(0, 0, 0, 0)) {
        return true;
      }

      return false;
    },
    getShortDateFormat(date, code = null) {
      const data = new Date(date);
      const options = { month: "short", day: "numeric" };
      return code === "WEB"
        ? "NEVER"
        : data.toLocaleDateString("en-US", options);
    },
  },
};
