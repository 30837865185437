<template>
    <div class="survey-public-page">
      <LandingPage />
      <Modal
      ref="tribiefyPublicSurveyPollModal"
      :title="modalTitle"
      :hideHeader="isSurveyCompleteMessage"
      :hideFooter="!isSurveyResult"
      @closeModal="reset"
    >
      <template #modal-body>
        <Survey
          v-if="isSurvey"
          :surveyPollData="surveyPollData"
          @showSurveyCompleteMessage="onShowSurveyCompleteMessage"
          class="tribeify-survey-welcome"
        />
        <SurveyResult v-else-if="isSurveyResult" :surveyResult="surveyResult">
          <div
            v-if="isPromoteResult"
            class="tribeify-promote-survey-poll-result-message"
          >
            It looks like you’ve already taken this
            {{ surveyPollType }}.<br />Here are your responses”.
          </div>
        </SurveyResult>
      </template>
    </Modal>
  </div>
</template>

<script>
import { TYPE } from "@/helpers/constants/survey";

import Modal from "@/components/common/modal";
import Survey from "@/components/common/Survey";
import LandingPage from './landingPage';

import surveyActions from "@/mixins/surveyActions.mixin";

export default {
  name: "SurveyPublicPage",
  mixins: [
    surveyActions,
  ],
  components: {
    Modal,
    Survey,
    LandingPage,
  },
  data() {
    return {
      isSurvey: false,
      isSurveyResult: false,
      isPromoteResult: false,
      modalTitle: "",
      surveyPollData: {},
      surveyResult: {},
      isSurveyCompleteMessage: false,
      surveyList: []
    };
  },
  computed: {
    surveyPollType() {
      return TYPE[
        `${this.surveyPollData.type || this.surveyResult.survey.type}`
      ].toLowerCase();
    },
    surveyCompletePointsStyle() {
      return this.surveyPollData.points.toString().length > 4
        ? { fontSize: "1em", top: "29%" }
        : {};
    },
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async init() {
      this.surveyList = await this.getSurveyGuest();
      if (0 < this.surveyList.length) {
        this.onSurveyPollSelect(this.surveyList[0]);
      }
    },
    reset() {
      this.isSurvey = false;
      this.isSurveyResult = false;
      this.modalTitle = "";
      this.surveyPollData = {};
      this.surveyResult = {};
      this.isSurveyCompleteMessage = false;

      clearInterval(this.__setSurveyBgIntervalId);
      clearInterval(this.__setSurveyModalIntervalId);
    },
    onSurveyPollSelect(payload) {
      this.isSurvey = true;
      this.surveyPollData = payload;
      this.$refs.tribiefyPublicSurveyPollModal.openModal();
      this.initSurveyBackground(payload);

      this.__setSurveyModalIntervalId = setInterval(() => {
        let surveyTribeifyModalSelector = document.querySelector(".tribeify-modal")
        if (null == surveyTribeifyModalSelector) {
          return;
        }
        clearInterval(this.__setSurveyModalIntervalId);
      })
    },
    onShowSurveyCompleteMessage() {
      this.isSurvey = false;
      this.isSurveyCompleteMessage = true;
    },
    async onCloseModal() {
      this.$refs.tribiefyPublicSurveyPollModal.closeModal();
      await this.init();
    },
    initSurveyBackground(payload) {
      if (payload?.content?.backgroundImage) {
        this.__setSurveyBgIntervalId = setInterval(() => {
          let tribeifyModalSelector = document.querySelector(".tribeify-modal");
          if (null == tribeifyModalSelector) {
            return;
          }
          tribeifyModalSelector.classList.add("survey-tribeify-modal");
          tribeifyModalSelector.style.setProperty('--surveyBackgroundImage', `url("${payload.content?.backgroundImage}")`);
          clearInterval(this.__setSurveyBgIntervalId);
        });
      }
    },
  }
};
</script>

<style lang="scss">
.survey-tribeify-modal {
  top: 34% !important;
  --surveyBackgroundImage: none;
  background-image: var(--surveyBackgroundImage);
  background-size: contain;
}
.sv-container-modern, .sv-completedpage {
  color: #0055b7;
}
.sv-previous-button-label {
  svg {
    fill: #fff;
  }
}
</style>